import React from "react";
import "../Privacypolicy/Privacypolicy.css";

const Privacypolicy = () => {
  return (
    <section className="privacy-policy">
      <h4 className="hh">Disclaimer & Privacy Policy</h4>
      <div className="container">
        {/* Disclaimer Section */}
        <h2>Disclaimer</h2>
        <p>
          This website is operated by an authorized channel partner of Nimbus The Palm Village - Shishir , UP RERA Registration No. UPRERAAGT24002. The content
          provided on this website is strictly for informational purposes. All
          images are for representational use only. Prices are subject to change
          without notice, and the availability of properties is not guaranteed.
          By accessing this website, users confirm that the information,
          including brochures and marketing materials, is solely for reference
          purposes, and no reliance should be placed on this information for
          making any booking or purchase of property in any project of the
          company. This website does not constitute advertising, marketing,
          booking, selling, or an offer to sell, nor does it represent an
          invitation to purchase any unit in any project of the company. The
          company assumes no liability for any actions taken by the user based
          on material or information from this website. The company has taken
          steps to verify the information and project compliances, including
          ensuring that the projects listed comply with the Real Estate
          Regulation Act (RERA) 2016. However, users should independently verify
          the RERA registration status of real estate projects before making any
          purchase decisions.
        </p>

        {/* Privacy Policy Section */}
        <h2>Privacy Policy</h2>
        <p>
          We are committed to protecting your personal information and have
          designed this privacy policy to safeguard your interests while using
          our website.
        </p>

        <h3>Updating Our Privacy Policy</h3>
        <p>
          This privacy policy is subject to periodic review and updates without
          prior notice. To stay informed of any changes, we encourage you to
          regularly review this policy.
        </p>

        <h3>User Information</h3>
        <p>
          By using our website, you agree to abide by our terms and consent to
          the collection and use of any personal information you provide. While
          some sections of our website do not require personal information,
          certain features may require you to provide details such as your name,
          email address, or phone number. This information is used to deliver
          relevant services and to respond to communications or provide updates.
          You may opt out of our mailing list at any time. Rest assured, we do
          not share your personal information with any third party without your
          consent.
        </p>

        <h3>Security</h3>
        <p>
          To ensure the security of your sensitive information during
          transmission, we utilize advanced encryption protocols for all
          exchanges between your device and our servers. We also enforce
          restricted access and maintain high levels of confidentiality within
          our organization.
        </p>
      </div>
    </section>
  );
};

export default Privacypolicy;
