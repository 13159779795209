import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import Navbar from "../Navbar/Navbar";
import Home from "../Home/Home";
import Swal from "sweetalert2";
import Side from "../SidePos/Side";
import Modal from "../Modal/Modal";
import { MdSupportAgent } from "react-icons/md";
import { FaCarOn } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import Footer from "../Footer/Footer";

function Homepage() {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const hideModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name = /^[a-zA-Z\s]*$/.test(value)
          ? ""
          : "Name can only contain alphabets";
        break;
      case "email":
        errors.email = /\S+@\S+\.\S+/.test(value)
          ? ""
          : "Please enter a valid email address";
        break;
      case "mobile":
        errors.mobile = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number must be 10 digits";
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_v4vfeg8",
        "template_q8xwz9k",
        "#modal-form",
        "qfKVeiD_DgSTnVKYW"
      )
      .then(
        (result) => {
         
          Swal.fire({
            title: "Form Submitted Successfully!",
            text: "Thanks you for expressing interest on our website our expert will get in touch with you shortly",
            imageUrl: "ty.jpg",
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
          });
          hideModal();
          setIsSubmitting(false);
        },
        (error) => {
          
          Swal.fire({
            title: "Oops!",
            text: "There was an error submitting the form. Please try again.",
            icon: "error"
          });
          setIsSubmitting(false);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile } = formErrors;

    if (!name && !email && !mobile) {
      setIsSubmitting(true);
      sendEmail();
    } else {
      alert("Please fix the errors in the form");
    }
  };

  return (
    <>
      <Modal show={showModal} handleClose={hideModal}>
        <div className="modal-full">
          <div className="modalleft">
            <h4 className="modal-heading">We Promise</h4>
            <div className="modal-logo-left">
              <MdSupportAgent className="modal-logo1" />
              <p className="modal-sub-heading">Instant Call Back</p>
            </div>
            <div className="modal-logo-left">
              <FaCarOn className="modal-logo1" />
              <p className="modal-sub-heading">Free Site Visit</p>
            </div>
            <div className="modal-logo-left">
              <GiReceiveMoney className="modal-logo1" />
              <p className="modal-sub-heading">Unmatched Price</p>
            </div>
          </div>
          <div className="modalright">
            <h2 className="modal-heading">NIMBUS THE PALM VILLAGE</h2>
            <p className="modal-sub-heading">
              At Sector 22A, Yamuna Expressway
            </p>
            <form
              id="modal-form"
              className="modal-form"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
                {formErrors.name && <p className="error">{formErrors.name}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  required
                />
                {formErrors.email && (
                  <p className="error">{formErrors.email}</p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formValues.mobile}
                  onChange={handleChange}
                  required
                />
                {formErrors.mobile && (
                  <p className="error">{formErrors.mobile}</p>
                )}
              </div>
              <div className="butt-f">
                <button
                  className="submit-button-f"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Get Instant Call back"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Navbar />
      <Side />
      <Home />
      <Footer />
    </>
  );
}

export default Homepage;
