import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../SidePos/Side.css';
import Swal from "sweetalert2";
import Modal from 'react-modal';
import { FaPercent, FaCar, FaArrowAltCircleDown } from "react-icons/fa";
import { FaLocationDot, FaCarOn } from "react-icons/fa6";
import { MdSupportAgent } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";

const Side = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    mobile: '',
    email: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errors = { ...formErrors };

    switch (name) {
      case 'name':
        errors.name = /^[a-zA-Z\s]*$/.test(value)
          ? ''
          : 'Name can only contain alphabets and spaces';
        break;
      case 'email':
        errors.email = /\S+@\S+\.\S+/.test(value)
          ? ''
          : 'Please enter a valid email address';
        break;
      case 'mobile':
        errors.mobile = /^\d{10}$/.test(value)
          ? ''
          : 'Mobile number must be 10 digits';
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const sendEmail = () => {
    emailjs.send(
      'service_v4vfeg8',
      'template_q8xwz9k',
      formData,
      'qfKVeiD_DgSTnVKYW'
    ).then((result) => {
      
      Swal.fire({
        title: "Form Submitted Successfully!",
        text: "Thanks you for expressing interest on our website our expert will get in touch with you shortly",
        imageUrl: "ty.jpg",
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
      });
      setIsSubmitting(false);
      setModalIsOpen(false);
    }, (error) => {
      
      Swal.fire({
        title: "Oops!",
        text: "There was an error submitting the form. Please try again.",
        icon: "error"
      });
      setIsSubmitting(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile } = formErrors;

    if (!name && !email && !mobile) {
      setIsSubmitting(true);
      sendEmail();
    } else {
      alert('Please fix the errors in the form');
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="site-visit-section">
      <h2>Organize Site Visit</h2>
      <div className="butt-div">
        <button className="button-13" onClick={openModal}>Call Back</button>
      </div>
      <form onSubmit={handleSubmit} className="site-visit-form">
        <h3>Pre-Register here for Best Offers</h3>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {formErrors.name && <p className="error">{formErrors.name}</p>}
        </label>
        <label>
          Mobile No:
          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
          {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}
        </label>
        <label>
          E-Mail Address:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {formErrors.email && <p className="error">{formErrors.email}</p>}
        </label>
        <div className="butt-div">
          <button className="button-14" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Express Interest'}
          </button>
        </div>
      </form>
      <h2 className="booksv">Book a Site Visit</h2>
      <div className="property-details">
        <p>✓ Price: ₹ 1.63 Cr* Onwards</p>
        <p>✓ Typology: 3BHK Apartments</p>
        <p>✓ Location: Sector 22A, Yamuna Expressway</p>
      </div>
      <div className="logo-side">
        <div className="logo-main">
          <FaPercent className="logo-po" />
        </div>
        <div className="logo-main">
          <FaCar className="logo-po" />
        </div>
        <div className="logo-main">
          <FaLocationDot className="logo-po" />
        </div>
        <div className="logo-main">
          <FaArrowAltCircleDown className="logo-po" />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Call Back Modal"
        className="modal-main2"
      >
        <button className="close-button2" onClick={closeModal}>×</button>
        <div className="modal-full">
          <div className="modalleft">
            <h4 className="modal-heading">We Promise</h4>
            <div className="modal-logo-left">
              <MdSupportAgent className="modal-logo1" />
              <p className="modal-sub-heading">Instant Call Back</p>
            </div>
            <div className="modal-logo-left">
              <FaCarOn className="modal-logo1" />
              <p className="modal-sub-heading">Free Site Visit</p>
            </div>
            <div className="modal-logo-left">
              <GiReceiveMoney className="modal-logo1" />
              <p className="modal-sub-heading">Unmatched Price</p>
            </div>
          </div>
          <div className="modalright">
            <h2 className="modal-heading">NIMBUS THE PALM VILLAGE</h2>
            <p className="modal-sub-heading">At Sector 22A, Yamuna Expressway</p>
            <form id="modal-form" className="modal-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {formErrors.name && <p className="error">{formErrors.name}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formErrors.email && <p className="error">{formErrors.email}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                {formErrors.mobile && <p className="error">{formErrors.mobile}</p>}
              </div>
              <div className="butt-f">
                <button className="submit-button-f" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Enquire Now'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Side;
