import React from 'react';
import './App.css';
import Homepage from './Components/Homepage/Homepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Privacypolicy from './Components/Privacypolicy/Privacypolicy';


function App() {
  

  return (
    <>
     <Router>
     
      
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy" element={<Privacypolicy />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
