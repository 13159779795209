import React, { useState } from 'react';
import '../Navbar/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  const phoneClick = () => {
    window.location.href='tel:+919555768358';
  };
  return (
    <>
      <div>
        <header>
         <img src='logo.png' alt='logo' className='logo-header'/>
          <h1 className='head-h1'><span className='head-h1-span'>NIMBUS </span>THE PALM VILLAGE</h1>
          <p className='head-p'>AT SECTOR 22A, YAMUNA EXPRESSWAY</p>
          <p onClick={phoneClick} className='h'>+919555768358</p>
        </header>
        <nav>
          <ul className={isOpen ? 'active' : ''}>
            <a href="#home" onClick={closeMenu}><li>Home</li></a>
            <a href="#price" onClick={closeMenu}><li>Price</li></a>
            <a href="#s&f" onClick={closeMenu}><li>Site & Floor Plan</li></a>
            <a href="#amen" onClick={closeMenu}><li>Amenities</li></a>
            <a href="#gal" onClick={closeMenu}><li>Gallery</li></a>
            <a href="#loc" onClick={closeMenu}><li>Location</li></a>
            {/*-
            <a href="#vst" onClick={closeMenu}><li>Virtual Site Tour</li></a>
            -*/}
            <a href="#br" onClick={closeMenu}><li>Brochure</li></a>
          </ul>
          <div className="handle" onClick={toggleMenu}>
            {isOpen ? 'Close' : 'Menu'}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
