import React, { useState } from "react";
import "../Home/Home.css";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { FaLocationDot, FaRegCirclePlay, FaCarOn } from "react-icons/fa6";
import { MdSupportAgent } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import emailjs from "emailjs-com";

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [enquiryModalIsOpen, setEnquiryModalIsOpen] = useState(false);
  const [brochureModalIsOpen, setBrochureModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const openEnquiryModal = () => {
    setEnquiryModalIsOpen(true);
  };

  const closeEnquiryModal = () => {
    setEnquiryModalIsOpen(false);
  };

  const openBrochureModal = () => {
    setBrochureModalIsOpen(true);
  };

  const closeBrochureModal = () => {
    setBrochureModalIsOpen(false);
  };

  const openDetailsModal = () => {
    setDetailsModalIsOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalIsOpen(false);
  };

  const validateName = (value) => {
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      setNameError("Name can only contain alphabets");
    } else {
      setNameError("");
    }
    setName(value);
  };

  const validateEmail = (value) => {
    if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
    setEmail(value);
  };

  const validateMobile = (value) => {
    if (!/^\d{10}$/.test(value)) {
      setMobileError("Mobile number must be 10 digits");
    } else {
      setMobileError("");
    }
    setMobile(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!nameError && !emailError && !mobileError) {
      setIsSubmitting(true);

      const templateParams = {
        name,
        email,
        mobile,
      };

      emailjs
        .send(
          "service_v4vfeg8",
          "template_q8xwz9k",
          templateParams,
          "qfKVeiD_DgSTnVKYW"
        )
        .then((response) => {
          Swal.fire({
            title: "Form Submitted Successfully!",
            text: "Thanks you for expressing interest on our website our expert will get in touch with you shortly",
            imageUrl: "ty.jpg",
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
          });
          setIsSubmitting(false);
          closeEnquiryModal();
        })
        .catch((error) => {
          Swal.fire({
            title: "Oops!",
            text: "There was an error submitting the form. Please try again.",
            icon: "error"
          });
          setIsSubmitting(false);
        });
    } else {
      alert("Please fix the errors in the form");
    }
  };

  const images = ["gn1.webp", "gn2.webp", "gn3.webp", "mk.jpeg"];

  return (
    <>
      <section id="home" className="slider-section">
        <div
          id="carouselExampleIndicators2"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="2000"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="gn3.webp" className="image-slider" alt="banner1" />
            </div>
            <div className="carousel-item">
              <img src="gn1.webp" className="image-slider" alt="banner2" />
            </div>
          </div>
          <button
            className="carousel-control-prev1"
            type="button"
            data-bs-target="#carouselExampleIndicators2"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next2"
            type="button"
            data-bs-target="#carouselExampleIndicators2"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
          <div className="carousel-overlay">
           
            <h2>NIMBUS <br/> THE PALM VILLAGE</h2>
            <p>AT SECTOR 22A, YAMUNA EXPRESSWAY</p>
            <p className="group-p">By Nimbus Group</p>
            <div className="offer-list">
              <div className="offer-div">
                <h3>Special Highlights</h3>
                <ul>
                  <li>✓ Luxury Living Redefined at Nimbus </li>
                  <li>✓ SBI Approved Project</li>
                  <li>✓ 1 BHK Studio Highly Demanded</li>
                  <li>✓ Big Size 3 BHK & 3 BHK + SQ</li>
                  <li>✓ Number Of Floor- G+4</li>
                  <li>✓ Only 2 Flats On Per Floor</li>
                  <li>✓ Only 470 Flats in 10 Acre</li>
                </ul>
              </div>
            </div>
            <p className="price-info">
              Low Rise 3BHK Apartments
              <br />
              Starts From ₹ 1.63 Cr* Onwards
              <br />
              1 BHK Studio flats 
              <br />
              Starts From ₹ 50 L* Onwards
            </p>
            <button className="button-13" onClick={openEnquiryModal}>
              Enquire Now
            </button>
          </div>
        </div>
      </section>

      <Modal
        isOpen={enquiryModalIsOpen}
        onRequestClose={closeEnquiryModal}
        contentLabel="Enquiry Modal"
        className="modal-main2"
      >
        <button className="close-button2" onClick={closeEnquiryModal}>
          ×
        </button>
        <div className="modal-full">
          <div className="modalleft">
            <h4 className="modal-heading">We Promise</h4>
            <div className="modal-logo-left">
              <MdSupportAgent className="modal-logo1" />
              <p className="modal-sub-heading">Instant Call Back </p>
            </div>
            <div className="modal-logo-left">
              <FaCarOn className="modal-logo1" />
              <p className="modal-sub-heading">Free Site Visit</p>
            </div>
            <div className="modal-logo-left">
              <GiReceiveMoney className="modal-logo1" />
              <p className="modal-sub-heading">Unmatched Price</p>
            </div>
          </div>
          <div className="modalright">
            <h2 className="modal-heading">NIMBUS THE PALM VILLAGE</h2>
            <p className="modal-sub-heading">
              At Sector 22A, Yamuna Expressway
            </p>
            <form className="modal-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => validateName(e.target.value)}
                  required
                />
                {nameError && <p className="error">{nameError}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                  required
                />
                {emailError && <p className="error">{emailError}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => validateMobile(e.target.value)}
                  required
                />
                {mobileError && <p className="error">{mobileError}</p>}
              </div>
              <div className="butt-f">
                <button
                  className="submit-button-f"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Enquire Now"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <section id="overview" className="overview-section">
        <div className="all-overview">
          <h1 className="head">Overview</h1>
          <div className="container">
            <div className="div-cont">
              <h2 className="section-title">
                NIMBUS THE PALM VILLAGE YAMUNA EXPRESSWAY
              </h2>
              <p className="location">AT SECTOR 22A, YAMUNA EXPRESSWAY</p>
              <p className="description">
                Nimbus The Palm Village in Sector 22A, Yamuna Expressway
                opposite F1 Track, independent Floors, where elegant low-rise
                G+4 structures and towering high-rise buildings of 13 floors
                stand crafted to the highest specifications. Each residence
                features a distinctive character that embodies harmony, peaceful
                living, and privacy.The thoughtful design prioritizes open-plan living spaces,
                seamlessly extending into balconies, ensuring abundant natural
                light and air ventilation. This enhances the sense of
                spaciousness and well-being throughout the apartments.
              </p>
             
              <p id="br" className="advantages">
                Nimbus The Palm Village are strategically located in Sector 22A,
                offers unparalleled convenience with its well-developed social
                infrastructure. Residents enjoy easy access to medical
                facilities, shopping streets, and a vibrant social scene,
                ensuring a comfortable and connected lifestyle. Embrace a life of tranquility and sophistication at The Palm
                Village, where every detail is designed for your utmost comfort
                and satisfaction.
              </p>
             
              <div className="overview-button" id="br2">
                <button className="button-34" onClick={openBrochureModal}>
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="about-overview">
          <div className="containerr">
            <p className="location">About Nimbus Group</p>
            <p className="description">
              Welcome to Nimbus The Palm Village in Sector 22A, Yamuna
              Expressway, opposite the F1 Track. Our elegant low-rise and
              high-rise residences, designed for harmony, privacy, and peaceful
              living, offer open-plan spaces with abundant natural light and
              ventilation. Strategically located, we provide unparalleled
              convenience with easy access to medical facilities, shopping, and
              vibrant social scenes. Embrace a life of tranquility and
              sophistication at Nimbus The Palm Village, crafted for your utmost
              comfort and satisfaction.
            </p>
          </div>
        </div>

        <div className="project-highlights">
          <h2>Project Highlights</h2>
          <ul>
            <li>Approx 12 Acre land</li>
            <li>470 no of units & all are 3BHK independent floors</li>
            <li>
              Two units per floor. Designed in such a way to have light ,
              ventilation and lush green view.
            </li>
            <li>Structure G+4 with lift.</li>
            <li>⁠Top floor units will have Terrace rights.</li>
            <li>⁠⁠Swimming Pool, Tennis, Basketball & Badminton court.</li>
            <li>Club house with indoor games facility.</li>
            <li>
              Two Blocks of 1BHK Studio: ⁠26 units on per floor, UGF + 13 floor
              structure, ⁠Furnished studio: ⁠Area 525 sqft.
            </li>
            <li>
              Convenient Access To Essential Amenities, Including Schools,
              Hospitals, Shopping Centers, And Entertainment Options
            </li>
            <li>
              Strategically Located In Sector 22A, Offering Excellent
              Connectivity To Major Hubs In Delhi NCR
            </li>
            <li>Prime Location</li>
          </ul>
        </div>
      </section>

      <Modal
        isOpen={brochureModalIsOpen}
        onRequestClose={closeBrochureModal}
        contentLabel="Brochure Modal"
        className="modal-main2"
      >
        <button className="close-button2" onClick={closeBrochureModal}>
          ×
        </button>
        <div className="modal-full">
          <div className="modalleft">
            <h4 className="modal-heading">We Promise</h4>
            <div className="modal-logo-left">
              <MdSupportAgent className="modal-logo1" />
              <p className="modal-sub-heading">Instant Call Back </p>
            </div>
            <div className="modal-logo-left">
              <FaCarOn className="modal-logo1" />
              <p className="modal-sub-heading">Free Site Visit</p>
            </div>
            <div className="modal-logo-left">
              <GiReceiveMoney className="modal-logo1" />
              <p className="modal-sub-heading">Unmatched Price</p>
            </div>
          </div>
          <div className="modalright">
            <h2 className="modal-heading">NIMBUS THE PALM VILLAGE</h2>
            <p className="modal-sub-heading">
              At Sector 22A, Yamuna Expressway
            </p>
            <form className="modal-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => validateName(e.target.value)}
                  required
                />
                {nameError && <p className="error">{nameError}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                  required
                />
                {emailError && <p className="error">{emailError}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => validateMobile(e.target.value)}
                  required
                />
                {mobileError && <p className="error">{mobileError}</p>}
              </div>
              <div className="butt-f">
                <button
                  className="submit-button-f"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Click To Download"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <section id="price" className="price-section-main">
        <div className="price-head-div">
          <h1 className="head">Price</h1>
        </div>
        <div className="price-section">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Carpet Area</th>
                <th>Final Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* Data for 3 BHK */}
              <tr>
                <td>1 BHK (Studio)</td>
                <td>Area On Request *</td>
                <td>On Request *</td>
                <td>
                  <button className="button-13" onClick={openDetailsModal}>
                    Get Details
                  </button>
                </td>
              </tr>
              {/* Data for 4 BHK */}
              <tr>
                <td>3 BHK</td>
                <td>Area On Request *</td>
                <td>On Request*</td>
                <td>
                  <button className="button-13" onClick={openDetailsModal}>
                    Get Details
                  </button>
                </td>
              </tr>
              {/* Data for 4 BHK + Servant Room */}
              <tr>
                <td>3 BHK + SQ</td>
                <td>Area On Request *</td>
                <td>On Request*</td>
                <td>
                  <button className="button-13" onClick={openDetailsModal}>
                    Get Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="div-price-image">
            <a className="anc" onClick={openEnquiryModal}>
              <div className="darksoul-card2">
                <div className="circle2" />
                <img
                  src="rupi.png"
                  alt="Dark"
                  width={49}
                  height={60}
                  style={{ zIndex: 0 }}
                />
                <p className="content">Know Price</p>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Modal
        isOpen={detailsModalIsOpen}
        onRequestClose={closeDetailsModal}
        contentLabel="Details Modal"
        className="modal-main2"
      >
        <button className="close-button2" onClick={closeDetailsModal}>
          ×
        </button>
        <div className="modal-full">
          <div className="modalleft">
            <h4 className="modal-heading">We Promise</h4>
            <div className="modal-logo-left">
              <MdSupportAgent className="modal-logo1" />
              <p className="modal-sub-heading">Instant Call Back </p>
            </div>
            <div className="modal-logo-left">
              <FaCarOn className="modal-logo1" />
              <p className="modal-sub-heading">Free Site Visit</p>
            </div>
            <div className="modal-logo-left">
              <GiReceiveMoney className="modal-logo1" />
              <p className="modal-sub-heading">Unmatched Price</p>
            </div>
          </div>
          <div className="modalright">
            <h2 className="modal-heading">NIMBUS THE PALM VILLAGE</h2>
            <p className="modal-sub-heading">
              At Sector 22A, Yamuna Expressway
            </p>
            <form className="modal-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => validateName(e.target.value)}
                  required
                />
                {nameError && <p className="error">{nameError}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                  required
                />
                {emailError && <p className="error">{emailError}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => validateMobile(e.target.value)}
                  required
                />
                {mobileError && <p className="error">{mobileError}</p>}
              </div>
              <div className="butt-f">
                <button
                  className="submit-button-f"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Get Price Details"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <section id="s&f" className="sfp-section-main">
        <div className="sfp-head-div">
          <h1 className="head">Site & Floor Plan</h1>
        </div>
        <div className="site-container">
          <div onClick={openEnquiryModal} className="image-container-s">
            <img src="flo.jpg" alt="Image 1" className="image" />
            <div className="overlay">Request Master Plan Layout</div>
          </div>
          <div onClick={openEnquiryModal} className="image-container-s">
            <img src="flo.jpg" alt="Image 2" className="image" />
            <div className="overlay">Request Master Plan Layout</div>
          </div>
          <div onClick={openEnquiryModal} className="image-container-s">
            <img src="flo.jpg" alt="Image 3" className="image" />
            <div className="overlay">Request Master Plan Layout</div>
          </div>
        </div>
      </section>
      <section id="amen" className="amenities-section-main">
        <div className="amen-head-div">
          <h1 className="head">Amenities</h1>
        </div>
        <div className="amenities-section">
          <div className="amenities-car">
            <div
              id="carouselExampleFade"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="div-car">
                    <ul>
                      <li> KIDS PLAY AREA</li>
                      <li>POWER BACKUP</li>
                      <li> OUTDOOR SPACES</li>
                    </ul>
                    <ul>
                      <li>CAFFETERIA</li>
                      <li> ADVANCED CCTV SURVEILLANCE</li>
                      <li>GYM</li>
                    </ul>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="div-car">
                    <ul>
                      <li>MULTIPLAY COURT</li>
                      <li>SWIMMING POOL</li>
                      <li>LANDSCAPED GARDEN</li>
                    </ul>
                    <ul>
                      <li>CAR PARKING AREA</li>
                      <li>WATER SUPPLY</li>
                      <li>OUTDOOR SPACES</li>
                    </ul>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleFade"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section id="gal" className="gallery-section-main">
        <div className="gal-head-div">
          <h1 className="head">Gallery</h1>
        </div>
        <div className="gallery-section">
          <div className="App1">
            <div className="image-grid">
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img
                    src={image}
                    alt={`img-${index}`}
                    onClick={() => openModal(image)}
                  />
                </div>
              ))}
            </div>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Image Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              <button className="close-button" onClick={closeModal}>
                ×
              </button>
              {selectedImage && (
                <img className="modal-img" src={selectedImage} alt="Selected" />
              )}
            </Modal>
          </div>
        </div>
      </section>
      <section id="loc" className="location-section">
        <div className="lo-head-div">
          <h1 className="head">Location</h1>
        </div>
        <div className="location-main">
          <div className="responsive-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.2329252501336!2d77.49982277393003!3d28.44239409269247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1b8663df523%3A0x8a8f7fb297b0fc9f!2sNimbus%20The%20Palm%20Village!5e0!3m2!1sen!2sin!4v1719646091103!5m2!1sen!2sin"
              width={600}
              height={450}
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
        </div>
        <div className="loc-detail">
          <div className="loc-div1">
            <div className="loc-text">
              <FaLocationDot className="icon-n" />
              <p className="text-lo"> 25 Mins. - Noida International Airport</p>
            </div>
            <div className="loc-text">
              <FaLocationDot className="icon-n" />
              <p className="text-lo"> 5 Mins. - Patanjali</p>
            </div>
            <div className="loc-text">
              <FaLocationDot className="icon-n" />
              <p className="text-lo"> 5 Mins. - Cricket Stadium</p>
            </div>
            <div className="loc-text">
              <FaLocationDot className="icon-n" />
              <p className="text-lo"> 15 Mins. - Vivo</p>
            </div>
            <div className="loc-text">
              <FaLocationDot className="icon-n" />
              <p className="text-lo"> 15 Mins. - Film City</p>
            </div>
            <div className="loc-text">
              <FaLocationDot className="icon-n" />
              <p className="text-lo"> 15 Mins. - Oppo</p>
            </div>
          </div>
        </div>
      </section>

      {/*--
      <section id='vst' className='video-section-main'>
      <div className='video-head-div'>
      <h1 className="head">Virtual Site Tour</h1>
      </div>
<div  className='video-section'>

      <div className="video-container">
      <video ref={videoRef} className="video" onClick={openEnquiryModal} >
        <source src="vi.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <>
          <div className="overlay-text">
            <h1 className='over-text0'>Virtual Tour</h1>
            <p>Ace Residential at Sector 12, Noida Extension</p>
          </div>

          <button className="play-button" onClick={openEnquiryModal} ><FaRegCirclePlay className='logo-vi' /></button>
        </>
      )}
    </div>
    
    </div>
    </section>
    --*/}
    </>
  );
};

export default Home;
