import React, { useState } from "react";
import "../Footer/Footer.css";
const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const phoneClick = () => {
    window.location.href = "tel:+919555768358";
  };
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-right">
            <div className="footer-con">
              <p className="f-pp">Mobile Number</p>
              <p className="num" onClick={phoneClick}>
                +919555768358
              </p>
            </div>
            <div className="footer-con">
              <p className="f-pp">Location</p>
              <p>
                Sector-22A, Yamuna Expy, Noida, Greater Noida, Uttar Pradesh
                201310
              </p>
            </div>
            <div className="footer-con">
              <p className="f-pp">Project Rera</p>
              <p>UPRERAPRJ558356/04/2024</p>
            </div>
          </div>
        </div>
        <p className="disclaimer-f">
        © 2024 Nimbus The Palm Village website is operated by an authorized channel
        partner of Nimbus The Palm Village - Shishir, UP RERA Registration No.
        UPRERAAGT24002. The information provided on this website is intended for
        informational purposes only. All images are for illustrative purposes.
        Prices are subject to change without prior notice, and the availability
        of properties may vary. By providing your contact information, you may
        receive updates via the registered mobile number and email.
        </p>
        <div className="footer-links">
        <a href="/privacy" className="footer-link">
          Disclaimer & Privacy Policy
        </a>
      </div>

        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close-button" onClick={handleCloseModal}>
                &times;
              </span>
              <img src="details.jpeg" alt="Agent RERA Details" />
            </div>
          </div>
        )}
      </footer>
    </>
  );
};

export default Footer;
